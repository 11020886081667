import {
  AttractionExtraOption,
  AttractionGroupAvailability,
  AttractionGroupExtraOptionAvailability,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionGroup,
  AttractionVariant,
} from "@twocontinents/dashboard/shared";
import { TimeSettingsEntity, uniqueArray } from "@twocontinents/shared";

export class AttractionGroupEntity {
  public id: number;
  public description: string;
  public defaultSlotsAvailable: number;
  public attractionVariants: AttractionVariant[];
  public availabilities: AttractionGroupAvailability[] = [];
  public extraOptionAvailabilities: AttractionGroupExtraOptionAvailability[] =
    [];
  public isGroupTimed: boolean;
  public times: string[];
  public extraOptions: AttractionExtraOption[];

  public constructor(group: AttractionGroup) {
    this.id = group.id;
    this.description = group.description;
    this.defaultSlotsAvailable = group.defaultSlotsAvailable;
    this.attractionVariants = group.attractionVariants;

    const { isGroupTimed, times, extraOptions } = this.extractVariants(
      this.attractionVariants,
    );

    this.isGroupTimed = isGroupTimed;
    this.times = times;
    this.extraOptions = extraOptions;
  }

  private extractVariants(variants: AttractionVariant[]) {
    let isGroupTimed = false;
    let times: string[] = [];
    const extraOptionsMap = new Map<number, AttractionExtraOption>();

    variants.map(({ timeSettings, extraOptions }) => {
      const {
        timed,
        timeZone,
        timeInterval,
        timeStart,
        timeFinish,
        pickupTimed,
      } = timeSettings;

      if (timed) {
        isGroupTimed = true;
      }

      const timeSlots = new TimeSettingsEntity(
        timed,
        pickupTimed,
        timeZone,
        timeInterval,
        timeStart,
        timeFinish,
      ).generateTimeSlots();

      times.push(...timeSlots);
      if (extraOptions) {
        extraOptions.forEach((option) => {
          extraOptionsMap.set(option.id, option);
        });
      }
    });

    times = uniqueArray(times).sort();
    const uniqueExtraOptions = [...extraOptionsMap.values()];

    return { isGroupTimed, times, extraOptions: uniqueExtraOptions };
  }
}
