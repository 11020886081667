import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useEffect, useMemo } from "react";

import { useGetAttractionGroupAvailability } from "../data-access";
import { AttractionGroupEntity, AttractionGroupEntityList } from "../types";

interface UseAttractionGroups {
  attractionGroups: AttractionGroup[];
  groupId: number;
  onGroupChange?: (attractionGroup: AttractionGroupEntity) => void;
}

export const useAttractionGroups = ({
  attractionGroups,
  groupId,
  onGroupChange,
}: UseAttractionGroups) => {
  const selectedGroup = useMemo(() => {
    const group = new AttractionGroupEntityList(attractionGroups).findById(
      groupId,
    );

    if (group) {
      group.attractionVariants = group.attractionVariants.toSorted(
        (a, b) => a.id - b.id,
      );
    }

    return group;
  }, [groupId, attractionGroups]);

  const { availability } = useGetAttractionGroupAvailability(groupId);

  if (selectedGroup && availability) {
    selectedGroup.availabilities = availability.availabilities;
    selectedGroup.extraOptionAvailabilities =
      availability.extraOptionAvailabilities;
  }

  useEffect(() => {
    if (selectedGroup && onGroupChange) {
      onGroupChange(selectedGroup);
    }
    // Disable for correct app behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return {
    selectedGroup,
  };
};
