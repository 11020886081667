import { zodResolver } from "@hookform/resolvers/zod";
import { ALL_ITEMS_ID, AttractionGroup } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionGroupAvailabilities } from "../../../../data-access";
import { useAttractionGroups } from "../../../../hooks";
import {
  AttractionGroupEntity,
  AttractionGroupEntityList,
  DateAvailability,
  MergedAvailabilities,
} from "../../../../types";

const AvailabilityFormSchema = (attractionGroups: AttractionGroupEntityList) =>
  z
    .object({
      groupId: z.number(),
      availability: z.object({
        date: z.string(),
        time: z.string().optional(),
        slotsAvailable: z.coerce.number().min(-1),
      }),
      times: z.coerce.number().optional(),
      days: z.coerce.number().optional(),
    })
    .superRefine((data, ctx) => {
      const selectedGroup = attractionGroups.findById(data.groupId);
      if (selectedGroup?.isGroupTimed && !data.availability.time) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Time is required for timed groups",
          path: ["availability", "time"],
        });
      }
    });

export type AvailabilityForm = z.infer<
  ReturnType<typeof AvailabilityFormSchema>
>;

const defaultAvailability: DateAvailability = new DateAvailability({
  date: "",
  slotsAvailable: -1,
  time: undefined,
});

export const useAvailabilitySettings = (
  attractionGroups: AttractionGroup[],
  attractionId: number,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const [mutatedAvailabilities, setMutatedAvailabilities] = useState<
    DateAvailability[]
  >([]);

  const { updateGroupAvailability, isPending } =
    useUpdateAttractionGroupAvailabilities();

  const form = useForm<AvailabilityForm>({
    resolver: zodResolver(
      AvailabilityFormSchema(new AttractionGroupEntityList(attractionGroups)),
    ),
    defaultValues: {
      availability: defaultAvailability,
      times: 1,
      days: 1,
    },
  });

  const selectedDate = form.watch("availability.date");

  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, watch, setValue } = form;

  const resetFormToDefaults = () => {
    setValue("availability.date", "");
    setValue("availability.time", undefined);
    setValue("times", 1);
    setValue("days", 1);
  };

  const onSubmit = handleSubmit(({ availability, times = 1, days = 1 }) => {
    const availabilities = generateAvailabilitiesInInterval(
      selectedGroup,
      availability,
      times,
      days,
    );

    setMutatedAvailabilities((prev) => {
      const mergedAvailabilities = new MergedAvailabilities({
        secondary: prev,
        primary: availabilities,
      });
      return mergedAvailabilities.merged;
    });

    resetFormToDefaults();
  });

  const onSubmitUnavailable = () => {
    setValue("availability.slotsAvailable", 0);
    formRef.current?.requestSubmit();
  };

  const { date, time } = watch("availability");
  const groupId = watch("groupId");

  const { selectedGroup } = useAttractionGroups({
    attractionGroups,
    groupId,
  });

  const times = selectedGroup?.times ?? [];
  const isGroupTimed = selectedGroup?.isGroupTimed ?? false;

  const getAvailableSlotsByTime = (time: string) => {
    return (
      selectedGroup?.availabilities.find(
        (availability) =>
          availability.time === time && availability.date === selectedDate,
      )?.slotsAvailable ?? selectedGroup?.defaultSlotsAvailable
    );
  };

  const showSubmitButtons =
    form.formState.isValid && (isGroupTimed ? Boolean(time) : true);

  const saveMutatedAvailabilities = () => {
    updateGroupAvailability({
      attractionId,
      groupId,
      body: {
        availabilities: mutatedAvailabilities.map((availability) => ({
          date: availability.date,
          time: availability.time,
          slotsAvailable: availability.slotsAvailable,
        })),
      },
    });

    setMutatedAvailabilities([]);
  };
  const allAvailabilities = mergeFetchedAndMutatedAvailabilities(
    mutatedAvailabilities,
    selectedGroup,
  );

  const addButtonDisabled = !selectedGroup || !selectedDate;

  useEffect(() => {
    if (selectedGroup) {
      const selectedAvailability = allAvailabilities.findByDatetime(date, time);

      const slotsAvailable =
        selectedAvailability?.slotsAvailable ??
        selectedGroup.defaultSlotsAvailable;

      setValue("availability.slotsAvailable", slotsAvailable);
    }
    // cannot pass form as a dependency, it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time, selectedGroup]);

  return {
    form,
    onSubmit,
    isPending,
    times,
    isGroupTimed,
    mutatedAvailabilities,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    showSubmitButtons,
    formRef,
    getAvailableSlotsByTime,
    addButtonDisabled,
    selectedDate,
  };
};

const generateAvailabilitiesInInterval = (
  selectedGroup: AttractionGroupEntity | undefined,
  availability: AvailabilityForm["availability"],
  times: number,
  days: number,
) => {
  const initialDate = dayjs(availability.date);

  // Determine time slots to use
  const timeSlots =
    availability.time === ALL_ITEMS_ID.toString()
      ? (selectedGroup?.times ?? [])
      : [availability.time];

  const availabilities: DateAvailability[] = [];

  timeSlots.forEach((time) => {
    for (let i = 0; i < times; i++) {
      const newDate = initialDate.add(days * i, "day");
      const formattedDate = DateFormatter.formatToYYYYMMDD(newDate);

      availabilities.push(
        new DateAvailability({
          ...availability,
          date: formattedDate,
          time,
        }),
      );
    }
  });

  return availabilities;
};

const mergeFetchedAndMutatedAvailabilities = (
  mutatedAvailabilities: DateAvailability[],
  selectedGroup?: AttractionGroupEntity,
) => {
  const fetchedAvailabilities: DateAvailability[] = (
    selectedGroup?.availabilities ?? []
  ).map(
    (availability) =>
      new DateAvailability({
        ...availability,
      }),
  );

  return new MergedAvailabilities({
    secondary: fetchedAvailabilities,
    primary: mutatedAvailabilities,
  });
};
