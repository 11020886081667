import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroup,
  AttractionVariant,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGetVariantAvailabilities,
  useUpdateVariantAvailabilities,
} from "../../../data-access";
import { useAttractionVariants } from "../../../hooks";

const DescriptionSettingsSchema = z.object({
  variantId: z.number().nonnegative(),
  dateFrom: z.string(),
  dateTo: z.string(),
  enabled: z.boolean(),
});

type DescriptionSettingsSchema = z.infer<typeof DescriptionSettingsSchema>;

export const useAvailabilitySettings = (
  attractionGroups: AttractionGroup[],
) => {
  const form = useForm<DescriptionSettingsSchema>({
    resolver: zodResolver(DescriptionSettingsSchema),
  });
  const { updateVariantAvailabilities, isPending } =
    useUpdateVariantAvailabilities();

  const variantId = form.watch("variantId");
  const dateFrom = form.watch("dateFrom");
  const dateTo = form.watch("dateTo");
  const enabled = form.watch("enabled");

  const { availabilities } = useGetVariantAvailabilities(variantId);

  const onVariantChange = (variant: AttractionVariant) => {
    form.reset({
      dateFrom: undefined,
      dateTo: undefined,
    });

    form.setValue("variantId", variant.id);
  };

  useAttractionVariants({
    variantId,
    attractionGroups,
    onVariantChange,
  });

  const handleRangeChange = (range: DateRange) => {
    if (range?.from) {
      form.setValue("dateFrom", DateFormatter.formatToYYYYMMDD(range.from));
    }

    if (range?.to) {
      form.setValue("dateTo", DateFormatter.formatToYYYYMMDD(range.to));
    }
  };

  const onSubmit = form.handleSubmit(({ variantId, ...body }) => {
    updateVariantAvailabilities(
      {
        variantId,
        body,
      },
      {
        onSuccess: () => {
          form.reset({
            dateFrom: undefined,
            dateTo: undefined,
            variantId,
          });
        },
      },
    );
  });

  const enable = () => {
    form.setValue("enabled", true);
  };
  const disable = () => {
    form.setValue("enabled", false);
  };

  return {
    form,
    onSubmit,
    isPending,
    handleRangeChange,
    dateFrom,
    dateTo,
    enabled,
    enable,
    disable,
    availabilities,
  };
};
