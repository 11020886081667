import { Calendar, cn } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { useRef } from "react";
import {
  Button,
  type DateRange,
  type DayProps,
  useDayRender,
} from "react-day-picker";

import { VariantAvailability } from "../../../types";

dayjs.extend(minMax);

function Day({
  date,
  displayMonth,
  entries,
}: DayProps & {
  entries: VariantAvailability[];
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);

  const matchingEntry = entries?.find((entry) =>
    dayjs(entry.date).isSame(date, "day"),
  );

  const notAvailable = matchingEntry?.enabled === false;

  return (
    <div>
      <Button
        {...dayRender.buttonProps}
        ref={buttonRef}
        style={{
          margin: "0.2rem",
          width: "4rem",
          height: "4rem",
          position: "relative",
          backgroundColor: notAvailable ? "#991b1b" : "#16A34A",
        }}
      >
        <p className="font-bold">{dayRender.buttonProps.children}</p>
      </Button>
    </div>
  );
}

export function VariantAvailabilityCalendar({
  className,
  maxDate,
  minDate,
  entries,
  dayClassName,
  month,
  onMonthChange,
  dateFrom,
  dateTo,
  onChange,
}: React.HTMLAttributes<HTMLDivElement> & {
  maxDate?: Date;
  minDate?: Date;
  entries: VariantAvailability[];
  dayClassName?: string;
  month?: Date;
  onMonthChange?: (month: Date) => void;
  dateFrom?: string;
  dateTo?: string;
  onChange?: (range?: DateRange) => void;
}) {
  const range = {
    from: dateFrom ? DateFormatter.formatToNativeDate(dateFrom) : undefined,
    to: dateTo ? DateFormatter.formatToNativeDate(dateTo) : undefined,
  };

  return (
    <Calendar
      mode="range"
      month={month}
      onMonthChange={onMonthChange}
      defaultMonth={month}
      toDate={maxDate}
      fromDate={minDate}
      selected={range}
      onSelect={onChange}
      numberOfMonths={1}
      className={className}
      classNames={{
        root: "text-slate-50",
        day_disabled: "invisible",
        day_range_start: "!bg-blue-600",
        day_range_end: "!bg-blue-600",
        day_selected: cn(
          "!bg-blue-600 aria-selected:!bg-blue-600 hover:bg-blue-600",
        ),
        day: cn(
          "!aria-selected:bg-blue-600 border h-9 w-9 rounded-md",
          dayClassName,
        ),
      }}
      components={{
        Day: (props) => <Day {...props} entries={entries} />,
      }}
    />
  );
}
