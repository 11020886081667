import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = (variantId: number) => [
  "private-variant-availabilities",
  variantId,
];

export const useGetVariantAvailabilities = (variantId: number) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/attractions/variants/{id}/availabilities", {
          params: {
            path: {
              id: variantId,
            },
          },
        })
        .then((response) => response.data),
    queryKey: queryKey(variantId),
    enabled: Boolean(variantId),
  });

  return { availabilities: data?.availabilities ?? [], isLoading };
};

export const useInvalidateGetVariantAvailabilities = () => {
  const queryClient = useQueryClient();

  const invalidate = async (variantId: number) => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(variantId),
    });
  };

  return { invalidateVariantAvailabilities: invalidate };
};
