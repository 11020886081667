import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetVariantAvailabilities } from "./get-variant-availabilities";

type Body = components["schemas"]["UpdateVariantAvailabilities"];

interface Props {
  variantId: number;
  body: Body;
}

export const useUpdateVariantAvailabilities = () => {
  const apiClient = usePrivateApiClient();
  const { invalidateVariantAvailabilities } =
    useInvalidateGetVariantAvailabilities();

  const { mutate: updateVariantAvailabilities, isPending } = useMutation({
    mutationFn: ({ variantId, body }: Props) =>
      apiClient
        .PATCH(`/private/attractions/variants/{id}/availabilities`, {
          params: {
            path: {
              id: variantId,
            },
          },
          body,
        })
        .then((response) => response.data),

    onSuccess: async (_, { variantId }) => {
      toast.success("Variant availabilities have been updated successfully.");
      await invalidateVariantAvailabilities(variantId);
    },
    onError: () => {
      toast.error("Variant availabilities could not be updated");
    },
  });

  return { updateVariantAvailabilities, isPending };
};
