import { AttractionVariantSelectByGroups } from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionGroup,
  Button,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { DateRange } from "react-day-picker";

import { VariantAvailabilityCalendar } from "./calendar";
import { useAvailabilitySettings } from "./use-availability-settings";

interface AvailabilitySettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AvailabilitySettings = ({
  attractionGroups,
}: AvailabilitySettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    dateFrom,
    dateTo,
    handleRangeChange,
    enabled,
    enable,
    disable,
    availabilities,
  } = useAvailabilitySettings(attractionGroups);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Variant availability settings</SettingCard.Title>
        <SettingCard.Description>
          Change variant's availability
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelectByGroups
                        attractionGroups={attractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="enabled"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Activity
                    </FormItem.Label>
                    <FormItem.Control>
                      <div className="flex gap-2">
                        <Button
                          variant="positive"
                          className={
                            enabled ? "" : "bg-gray-600 hover:bg-gray-600"
                          }
                          onClick={enable}
                          type="button"
                        >
                          Enabled
                        </Button>
                        <Button
                          variant="destructive"
                          className={
                            enabled === false
                              ? ""
                              : "bg-gray-600 hover:bg-gray-600"
                          }
                          onClick={disable}
                          type="button"
                        >
                          Disabled
                        </Button>
                      </div>
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />

            <div className="w-full flex justify-center">
              <VariantAvailabilityCalendar
                entries={availabilities}
                onChange={(e) => handleRangeChange(e as DateRange)}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            </div>
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change variant's availability.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
